<template>
  <div>
    <header-area voltar="true" pageTitle="Hackers :: MINES" />
    <mines-content-area />
    <menu-area />
    <footer-area />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderArea from "@/components/HeaderArea.vue";
import MinesContentArea from "@/components/MinesContentArea.vue";
import MenuArea from "@/components/MenuArea.vue";
import FooterArea from "@/components/FooterArea.vue";

export default {
  name: "MinesView",
  components: {
    HeaderArea,
    MinesContentArea,
    MenuArea,
    FooterArea,
  },
};
</script>

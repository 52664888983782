<template>
  <div class="page-content-wrapper">
    <div class="container">
      <!-- Support Wrapper-->
      <div class="support-wrapper py-3">
        <div class="accordian-area-wrapper mt-3 bg-dark">
          <div class="card accordian-card">
            <div class="card-body">
              <h5 class="accordian-title">Iniciando</h5>
              <div class="accordion" id="accordion1">
                <div class="accordian-header" id="headingOne">
                  <button
                    class="d-flex align-items-center justify-content-between w-100 collapsed btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                    @click="isCollapsed1 = !isCollapsed1"
                  >
                    <span
                      ><i class="fa-solid fa-user"></i>Como abrir minha
                      conta?</span
                    ><i class="fa-solid fa-angle-right"></i>
                  </button>
                </div>
                <div
                  :class="{ show: isCollapsed1 }"
                  class="collapse"
                  id="collapseOne"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordion1"
                >
                  <p>
                    Para iniciar é importante que você abra a conta na casa de
                    apostas que o hacker funciona, lembrando que os sinais são
                    exclusivamente para essa casa, não tente usar em outra pois
                    ao invés de lucrar você pode perder dinheiro.
                  </p>
                  <p>
                    <router-link to="https://jrsports.bet/" target="_blank">Clicando aqui</router-link> você irá ser redirecionado para a
                    tela de cadastro, basta preencher suas informações e
                    finalizar.
                  </p>
                </div>
                <div class="accordian-header" id="headingTwo">
                  <button
                    class="d-flex align-items-center justify-content-between w-100 collapsed btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    @click="isCollapsed2 = !isCollapsed2"
                  >
                    <span
                      ><i class="fa-solid fa-download"></i>Como depósitar meu
                      saldo?</span
                    ><i class="fa-solid fa-angle-right"></i>
                  </button>
                </div>
                <div
                  :class="{ show: isCollapsed2 }"
                  class="collapse"
                  id="collapseTwo"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordion1"
                >
                  <p>
                    Ao concluí seu cadastro você irá realizar seu login com os
                    seus dados de acesso.
                  </p>
                  <p></p>
                  <div class="row g-2">
                    <div class="col-6">
                      <p>
                        Clique no botão <b>DEPÓSITO</b> no canto superior
                        direito
                      </p>
                    </div>
                    <div class="col-6">
                      <img
                        class="mb-3 rounded"
                        src="../assets/img/deposito.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <p></p>

                  <p></p>
                  <div class="row g-2">
                    <div class="col-6">
                      <img
                        class="mb-3 rounded"
                        src="../assets/img/deposito2.png"
                        alt=""
                      />
                    </div>
                    <div class="col-6">
                      <p>Selecione a forma de pagamento PIX</p>
                    </div>
                  </div>
                  <p></p>

                  <p></p>
                  <div class="row g-2">
                    <div class="col-6">
                      <p>
                        Insira o valor de depósito e clique em depositar (R$
                        100,00 recomendado)
                      </p>
                    </div>
                    <div class="col-6">
                      <img
                        class="mb-3 rounded"
                        src="../assets/img/deposito3.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <p></p>

                  <p></p>
                  <div class="row g-2">
                    <div class="col-6">
                      <img
                        class="mb-3 rounded"
                        src="../assets/img/deposito.png"
                        alt=""
                      />
                    </div>
                    <div class="col-6">
                      <p>
                        Copie ou escaneie o QR Code e pague pelo seu aplicativo
                        do banco
                      </p>
                    </div>
                  </div>
                  <p></p>
                  <p>
                    Após finalizar o pagamento seu saldo será atualizado
                    automáticamente, lembre-se de transferir de uma conta da sua
                    mesma titularidade, ou seja o mesmo CPF de cadastro deverá
                    ser o titular da conta de pagamento.
                  </p>
                </div>
                <div class="accordian-header" id="headingTree">
                  <button
                    class="d-flex align-items-center justify-content-between w-100 collapsed btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTree"
                    aria-expanded="false"
                    aria-controls="collapseTree"
                    @click="isCollapsed3 = !isCollapsed3"
                  >
                    <span
                      ><i class="fa-solid fa-upload"></i>Como sacar meu
                      saldo?</span
                    ><i class="fa-solid fa-angle-right"></i>
                  </button>
                </div>
                <div
                  :class="{ show: isCollapsed3 }"
                  class="collapse"
                  id="collapseTree"
                  aria-labelledby="headingTree"
                  data-bs-parent="#accordion1"
                >
                  <p>
                    Para realizar o saque do seu saldo, acesse a sua conta da
                    casa e siga os seguintes procedimentos abaixo.
                  </p>
                  <p></p>
                  <div class="row g-2">
                    <div class="col-6">
                      <p>
                        Clique no botão <b>DEPÓSITO</b> no canto superior
                        direito
                      </p>
                    </div>
                    <div class="col-6">
                      <img
                        class="mb-3 rounded"
                        src="../assets/img/deposito.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <p></p>

                  <p></p>
                  <div class="row g-2">
                    <div class="col-6">
                      <img
                        class="mb-3 rounded"
                        src="../assets/img/saque.png"
                        alt=""
                      />
                    </div>
                    <div class="col-6">
                      <p>Clique no método de <b>SAQUE</b></p>
                    </div>
                  </div>
                  <p></p>

                  <p></p>
                  <div class="row g-2">
                    <div class="col-6">
                      <p>Clique no método de pagamento <b>PIX</b></p>
                    </div>
                    <div class="col-6">
                      <img
                        class="mb-3 rounded"
                        src="../assets/img/saque2.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <p></p>

                  <p></p>
                  <div class="row g-2">
                    <div class="col-6">
                      <img
                        class="mb-3 rounded"
                        src="../assets/img/saque3.png"
                        alt=""
                      />
                    </div>
                    <div class="col-6">
                      <p>Insira o valor desejado e clique em <b>SACAR</b></p>
                    </div>
                  </div>
                  <p></p>
                  <p>
                    Após realizar o saque o dinheiro será transferido para sua
                    conta automáticamente, a conta a receber será a que tem sua
                    chave de <b>CPF</b> cadastrada.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordian-area-wrapper mt-3 bg-dark">
          <div class="card accordian-card">
            <div class="card-body">
              <h5 class="accordian-title">Utilizando o Hacker</h5>
              <div class="accordion" id="accordion1">
                <div class="accordian-header" id="heading4">
                  <button
                    class="d-flex align-items-center justify-content-between w-100 collapsed btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapse4"
                    @click="isCollapsed4 = !isCollapsed4"
                  >
                    <span><i class="fa-solid fa-cogs"></i>Como hackear?</span
                    ><i class="fa-solid fa-angle-right"></i>
                  </button>
                </div>
                <div
                  :class="{ show: isCollapsed4 }"
                  class="collapse"
                  id="collapse4"
                  aria-labelledby="heading4"
                  data-bs-parent="#accordion1"
                >
                  <p>
                    Para iniciar é importante que você já tenha sua conta na
                    casa aberta, caso ainda não realizou o cadastro, verifique
                    no primeiro tutorial desta página ou clique no botão abaixo
                    e faça seu cadastro.
                  </p>
                  <p>
                    Para utilizar o hacker é muito simples, basta clicar no menu
                    <b>HACKER</b> --&gt; Selecionar o jogo que deseja hackear Ao
                    carregar a tela do jogo, na parte superior você verá o sinal
                    hackeado, basta seguir exatamente o sinal informado no
                    aplicativo e executar no jogo que irá carregar
                    automaticamente na tela.
                  </p>
                  <p></p>
                </div>

                <div class="accordian-header" id="heading4">
                  <button
                    class="d-flex align-items-center justify-content-between w-100 collapsed btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapse5"
                    @click="isCollapsed5 = !isCollapsed5"
                  >
                    <span><i class="fa-solid fa-cogs"></i>Hacker Mines</span
                    ><i class="fa-solid fa-angle-right"></i>
                  </button>
                </div>
                <div
                  :class="{ show: isCollapsed5 }"
                  class="collapse"
                  id="collapse5"
                  aria-labelledby="heading4"
                  data-bs-parent="#accordion1"
                >
                  <div class="about-content-wrap dir-rtl mt-2">
                    <img class="mb-3" src="../assets/img/mines.png" alt="" />
                    <h5>Mines</h5>
                    <p>
                      Os sinais do Mines são indicados por estrelas, irá exibir
                      o padrão que você precisa executar no jogo e para isso,
                      basta abrir as caixas indicadas no sinal como na imagem
                      acima.
                    </p>
                    <p>
                      Após concluir a execução do sinal clique no botão
                      <b> Gerar novo Sinal</b> para renovar o hacker do jogo e
                      assim realizar novas apostas, cada sinal é válido por 3
                      tentativas ou seja, caso na terceira vez não funcione,
                      renove o sinal.
                    </p>
                    <br /><br />
                  </div>
                </div>

                <div class="accordian-header" id="heading4">
                  <button
                    class="d-flex align-items-center justify-content-between w-100 collapsed btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse6"
                    aria-expanded="false"
                    aria-controls="collapse6"
                    @click="isCollapsed6 = !isCollapsed6"
                  >
                    <span><i class="fa-solid fa-cogs"></i>Hacker Aviator</span
                    ><i class="fa-solid fa-angle-right"></i>
                  </button>
                </div>
                <div
                  :class="{ show: isCollapsed6 }"
                  class="collapse"
                  id="collapse6"
                  aria-labelledby="heading4"
                  data-bs-parent="#accordion1"
                >
                  <div class="about-content-wrap dir-rtl">
                    <img class="mb-3" src="../assets/img/aviator.png" alt="" />
                    <h5>Aviator</h5>
                    <p>
                      Os sinais do Aviator são indicados pelo número de
                      multiplicador em que você deve retirar a sua aposta. Na
                      imagem acima mostra o número 2.30x significa que você irá
                      inserir o valor e realizar a aposta, quando o jogo iniciar
                      você deverá acompanhar o avião subir até o número indicado
                      ou seja: <b>2.30x</b> quando for alcançado você deverá
                      retirar a sua aposta clicando no botão amarelo
                      <b>CASHOUT</b> na tela do jogo e obter o lucro
                    </p>
                    <p>
                      O numero retirado multiplica proporcionalmente na
                      quantidade da sua aposta, ou seja, se sua aposta foi de
                      R$10 e o multiplicador retirado foi 2.30x você irá ganhar
                      R$23,00 que é exatamente o valor apostado x número
                    </p>
                    <p>
                      Após concluir a execução do sinal clique no botão
                      <b> Gerar novo Sinal</b> para renovar o hacker do jogo e
                      assim realizar novas apostas, cada sinal é válido por 3
                      tentativas ou seja, caso na terceira vez não funcione,
                      renove o sinal.
                    </p>
                    <br /><br />
                  </div>
                </div>

                <!-- <div class="accordian-header" id="heading4">
                  <button
                    class="d-flex align-items-center justify-content-between w-100 collapsed btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse7"
                    aria-expanded="false"
                    aria-controls="collapse7"
                    @click="isCollapsed7 = !isCollapsed7"
                  >
                    <span
                      ><i class="fa-solid fa-cogs"></i>Hacker Football-X</span
                    ><i class="fa-solid fa-angle-right"></i>
                  </button>
                </div>
                <div
                  :class="{ show: isCollapsed7 }"
                  class="collapse"
                  id="collapse7"
                  aria-labelledby="heading4"
                  data-bs-parent="#accordion1"
                >
                  <div class="about-content-wrap dir-rtl">
                    <img class="mb-3" src="../assets/img/football.png" alt="" />
                    <h5>Football-X</h5>
                    <p>
                      Os sinais do Football-X são indicados pelo número de
                      multiplicador em que você deve retirar a sua aposta. Na
                      imagem acima mostra o número 3.90x significa que você irá
                      inserir o valor e realizar a aposta, e segurar o dedo nas
                      embaixadinhas até o número indicado ou seja:
                      <b>3.90x</b> quando for alcançado você deverá retirar a
                      sua aposta soltando o dedo da tela do jogo e obter o lucro
                    </p>
                    <p>
                      O numero retirado multiplica proporcionalmente na
                      quantidade da sua aposta, ou seja, se sua aposta foi de
                      R$10 e o multiplicador retirado foi 3.90x você irá ganhar
                      R$39,00 que é exatamente o valor apostado x número
                    </p>
                    <p>
                      Após concluir a execução do sinal clique no botão
                      <b> Gerar novo Sinal</b> para renovar o hacker do jogo e
                      assim realizar novas apostas, cada sinal é válido por 3
                      tentativas ou seja, caso na terceira vez não funcione,
                      renove o sinal.
                    </p>
                    <br /><br />
                  </div>
                </div> -->

                <div class="accordian-header" id="heading4">
                  <button
                    class="d-flex align-items-center justify-content-between w-100 collapsed btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse8"
                    aria-expanded="false"
                    aria-controls="collapse8"
                    @click="isCollapsed8 = !isCollapsed8"
                  >
                    <span
                      ><i class="fa-solid fa-cogs"></i>Hacker Luva Super
                      Gol</span
                    ><i class="fa-solid fa-angle-right"></i>
                  </button>
                </div>
                <div
                  :class="{ show: isCollapsed8 }"
                  class="collapse"
                  id="collapse8"
                  aria-labelledby="heading4"
                  data-bs-parent="#accordion1"
                >
                  <div class="about-content-wrap dir-rtl">
                    <img class="mb-3" src="/assets/img/rabbit.png" alt="" />
                    <h5>Luva Super Gol</h5>
                    <p>
                      Os sinais do Luva Super Gol são indicados pelo número de
                      multiplicador em que você deve retirar a sua aposta. Na
                      imagem acima mostra o número 2.93x significa que você irá
                      inserir o valor e realizar a aposta, quando o jogo iniciar
                      você deverá acompanhar a bola até o número:
                      <b>2.93x</b> quando for alcançado você deverá retirar a
                      sua aposta clicando no botão verde <b>SAQUE</b> na tela do
                      jogo e obter o lucro
                    </p>
                    <p>
                      O numero retirado multiplica proporcionalmente na
                      quantidade da sua aposta, ou seja, se sua aposta foi de
                      R$10 e o multiplicador retirado foi 2.93x você irá ganhar
                      R$29,30 que é exatamente o valor apostado x número
                    </p>
                    <p>
                      Após concluir a execução do sinal clique no botão
                      <b> Gerar novo Sinal</b> para renovar o hacker do jogo e
                      assim realizar novas apostas, cada sinal é válido por 3
                      tentativas ou seja, caso na terceira vez não funcione,
                      renove o sinal.
                    </p>
                    <br /><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapsed1: false,
      isCollapsed2: false,
      isCollapsed3: false,
      isCollapsed4: false,
      isCollapsed5: false,
      isCollapsed6: false,
      isCollapsed7: false,
      isCollapsed8: false,
    };
  },
};
</script>

<style>
.collapse p a {
  color: #84ff00;
}
</style>

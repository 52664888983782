<template>
  <div>
    <header-area voltar="true" pageTitle="Hackers :: ROLETA" />
    <roleta-content-area />
    <menu-area />
    <footer-area />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderArea from "@/components/HeaderArea.vue";
import RoletaContentArea from "@/components/RoletaContentArea.vue";
import MenuArea from "@/components/MenuArea.vue";
import FooterArea from "@/components/FooterArea.vue";

export default {
  name: "RoletaView",
  components: {
    HeaderArea,
    RoletaContentArea,
    MenuArea,
    FooterArea,
  },
};
</script>

<template>
  <div class="page-content-wrapper pb-3">
    <div
      class="vendor-details-wrap bg-img bg-overlay py-3"
      style="background-image: url('https://ciaa.io/images/banner_rabbit.png')"
    >
      <div class="container">
        <div class="d-flex align-items-start">
          <div class="vendor-info">
            <h5 class="vendor-title text-white">Sinal Confirmado:</h5>
            <div
              style="
                font-size: 50px;
                font-weight: 900;
                color: #fff;
                font-family: Verdana;
              "
            >
              {{ tip }}x
            </div>
          </div>
        </div>
        <div
          class="vendor-basic-info d-flex align-items-center justify-content-between"
        >
          <div class="single-basic-info">
            <input
              type="button"
              class="btn btn-warning"
              id="btn"
              value="Gerar novo sinal"
              @click="getRandomNumber()"
            />
          </div>
          <div class="single-basic-info"></div>
          <div class="single-basic-info">
            <div class="icon"><i class="fa-solid fa-refresh"></i></div>
            <span>Tentativas: 3 por sinal</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vendor-tabs bg-dark">
      <div class="container">
        <ul class="nav nav-tabs mb-3" id="vendorTab" role="tablist">
          <a
            href="https://jrsports.bet/clientes/deposito"
            target="_blank"
            ><li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="false"
              >
                DEPOSITAR
              </button>
            </li></a
          >

          <a
            href="https://jrsports.bet/clientes/saque"
            target="_blank"
            ><li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="false"
              >
                SACAR
              </button>
            </li></a
          >
        </ul>
      </div>
    </div>
    <div class="tab-content" id="vendorTabContent">
      <div
        class="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="container">
          <div class="card">
            <iframe
              src="https://jrsports.bet/casino/c/play/REAL/pgs-slot-FortuneRabbit/pgsoft"
              style="
                width: 100%;
                height: 800px;
                padding: 0px;
                margin: 0px;
                border: none;
                frameborder: none;
              "
            ></iframe>
            <div class="card-body about-content-wrap dir-rtl bg-dark">
              <div class="contact-btn-wrap text-center">
                <p class="mb-2 text-white">
                  Caso o jogo não carregue clique no botão abaixo.
                </p>
                <router-link class="btn btn-primary w-100" to="aviator"
                  ><i class="fa-solid fa-refresh me-2"></i>Recarregar</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tip: "0.00",
    };
  },
  methods: {
    getRandomNumber() {
      const lowerLimit = 1.01;
      const upperLimit = 4.99;

      const randomNumber =
        Math.random() * (upperLimit - lowerLimit) + lowerLimit;

      // Arredondar o número para duas casas decimais
      const roundedNumber = Number(randomNumber.toFixed(2));

      this.tip = roundedNumber;
    },
  },
};
</script>

<style scoped>
.vendor-info {
  margin-bottom: 20px;
}
</style>

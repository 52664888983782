<template>
  <div>
    <header-area voltar="true" pageTitle="Minha Conta" />
    <perfil-content-area />
    <menu-area />
    <footer-area />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderArea from "@/components/HeaderArea.vue";
import PerfilContentArea from "@/components/PerfilContentArea.vue";
import MenuArea from "@/components/MenuArea.vue";
import FooterArea from "@/components/FooterArea.vue";

export default {
  name: "AulasView",
  components: {
    HeaderArea,
    PerfilContentArea,
    MenuArea,
    FooterArea,
  },
};
</script>

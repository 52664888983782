<template>
  <div class="page-content-wrapper pb-3">
    <!-- Vendor Details Wrap -->
    <div
      class="vendor-details-wrap bg-img bg-overlay py-3"
      style="
        background-image: url('https://bethacker.app/img/bg-img/mines.png');
      "
    >
      <div class="container">
        <div class="d-flex align-items-start">
          <!-- Vendor Profile-->

          <!-- Vendor Info-->
          <div class="vendor-info">
            <h5 class="vendor-title text-white">Sinal Confirmado:</h5>
            <div class="text-white sinal-mines">
              <span>
                <p>
                  <i v-for="(icon, idx) in icons" :key="idx" :class="icon"></i>
                </p>
              </span>
            </div>
          </div>
        </div>
        <!-- Vendor Basic Info-->
        <div
          class="vendor-basic-info d-flex align-items-center justify-content-between"
        >
          <div class="single-basic-info">
            <input
              type="button"
              class="btn btn-warning"
              id="btn"
              value="Gerar novo sinal"
              @click="shuffleIcons()"
            />
          </div>
          <div class="single-basic-info">
            <div class="icon"><i class="fa-solid fa-clock"></i></div>
            <span>Validade: <b>5 Minutos</b></span>
          </div>
          <div class="single-basic-info">
            <div class="icon"><i class="fa-solid fa-refresh"></i></div>
            <span>Tentativas: 3</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Vendor Tabs -->
    <div class="vendor-tabs bg-dark">
      <div class="container">
        <ul class="nav nav-tabs mb-3" id="vendorTab" role="tablist">
          <a
            href="https://jrsports.bet/clientes/deposito"
            target="_blank"
            ><li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="false"
              >
                DEPOSITAR
              </button>
            </li></a
          >

          <a
            href="https://jrsports.bet/clientes/saque"
            target="_blank"
            ><li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="false"
              >
                SACAR
              </button>
            </li></a
          >
        </ul>
      </div>
    </div>
    <div class="tab-content" id="vendorTabContent">
      <div
        class="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div class="container">
          <div class="card">
            <iframe
              src="https://jrsports.bet/casino/c/play/REAL/spb-tb-mines/spribe"
              style="
                width: 100%;
                height: 800px;
                padding: 0px;
                margin: 0px;
                border: none;
                frameborder: none;
              "
            ></iframe>
            <div class="card-body about-content-wrap dir-rtl bg-dark">
              <div class="contact-btn-wrap text-center">
                <p class="mb-2 text-white">
                  Caso o jogo não carregue clique no botão abaixo.
                </p>
                <router-link class="btn btn-primary w-100" to="mines"
                  ><i class="fa-solid fa-refresh me-2"></i>Recarregar</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: [
        "fa-solid fa-star",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-star",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-star",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-star",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-square",
        "fa-solid fa-star",
      ],
    };
  },
  methods: {
    shuffleIcons() {
      const iconsCopy = [...this.icons]; // Cria uma cópia do array de ícones
      for (let i = iconsCopy.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // Gera um índice aleatório
        // Troca a posição dos ícones
        [iconsCopy[i], iconsCopy[j]] = [iconsCopy[j], iconsCopy[i]];
      }
      this.icons = iconsCopy; // Atualiza o array de ícones com a nova ordem aleatória
    },
  },
};
</script>

<style scoped>
.sinal-mines {
  margin-top: 10px;
  margin-bottom: 20px;
}

.sinal-mines span {
  width: 120px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  line-height: 0;
}
.sinal-mines p {
  margin: 0;
  line-height: 0;
  color: rgba(13, 110, 253, 1);
  font-size: 18px !important;
}

.sinal-mines p i {
  width: 1rem;
  margin: 2px 4px;
}

.sinal-mines .fa-star {
  color: #ffdb00;
}

.vendor-details-wrap .single-basic-info span {
  font-size: 12px;
}
</style>

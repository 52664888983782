import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  getters: {
    getToken: (state) => state.token,
    getUser: (state) => state.user,
  },
  actions: {
    logout({ commit }) {
      // Limpar o token e o usuário do state
      commit('setToken', null);
      commit('setUser', null);

      // Redirecionar para a página de login
      router.push('/login');
    },
  },
});

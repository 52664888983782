<template>
  <div class="footer-nav-area" id="footerNav">
    <div class="suha-footer-nav">
      <ul
        class="h-100 d-flex align-items-center justify-content-between ps-0 d-flex rtl-flex-d-row-r"
      >
        <li>
          <router-link to="/home"><i class="fa-solid fa-cubes"></i>Hackers</router-link>
        </li>
        <li>
          <router-link to="/aulas"><i class="fa-solid fa-graduation-cap"></i>Aulas</router-link>
        </li>
        <li>
          <router-link to="/perfil"><i class="fa-solid fa-user"></i>Conta</router-link>
        </li>
        <li>
          <a href="" @click="logout"><i class="fa-solid fa-sign-out"></i>Sair</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style></style>

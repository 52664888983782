<template>
  <div
    class="login-wrapper d-flex align-items-center justify-content-center text-center"
  >
    <div class="container">
      <div class="alert alert-danger alert-dismissible fade show" role="alert" v-show="alert">
        <i class="fa-solid fa-triangle-exclamation"></i> {{ alert }}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="alert = null"></button>
      </div>
      <div class="row justify-content-center">
        <div class="col-10 col-lg-8">
          <img
            class="big-logo"
            src="../assets/img/white.png"
            alt="white logo"
          />

          <div class="deposit-alert mt-5" v-if="ref">
            <h1>COMECE A LUCRAR NOS PRÓXIMOS MINUTOS</h1>
            <p>Faça login em sua conta e deposite o saldo para iniciar as apostas. Aconselhamos a iniciar com saldo entre R$ 10,00 e R$ 50,00 para lucrar em média <strong>R$ 100,00 POR DIA</strong></p>
            <div class="btn-deposit-alert py-1"><i class="fa-solid fa-bell"></i> DEPÓSITO E SAQUE VIA PIX EM SEGUNDOS</div>
          </div>

          <div class="register-form mt-5">
            <form action="realizar_login" method="POST">
              <div class="form-group text-start mb-4">
                <span>Usuário ou E-mail</span>
                <label for="username"><i class="fa-solid fa-user"></i></label>
                <input
                  class="form-control"
                  id="username"
                  name="usuario"
                  type="text"
                  placeholder="Usuário ou E-mail"
                  v-model="email"
                />
              </div>
              <div class="form-group text-start mb-4">
                <span>Senha</span>
                <label for="password"><i class="fa-solid fa-key"></i></label>
                <input
                  class="form-control"
                  id="password"
                  name="senha"
                  type="password"
                  placeholder="Digite sua senha"
                  v-model="password"
                />
                <div
                  class="password-meter-wrapper progress"
                  style="display: none"
                >
                  <div
                    id="password-progress"
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="1"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width: 1%"
                  ></div>
                </div>
                <div
                  id="password-score"
                  class="password-score"
                  style="display: none"
                ></div>
                <div
                  id="password-recommendation"
                  class="password-recommendation"
                ></div>
                <input type="hidden" id="password-strength-score" value="0" />
              </div>
              <button class="btn btn-warning btn-lg w-100" type="button" @click="handleSubmit">
                Entrar
              </button>
            </form>
          </div>
          <div class="login-meta-data">
            <p class="mb-0">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapGetters } from "vuex";
import { loginService } from '@/services/UserService.js';

export default {
  name: "LoginView",
  computed: {
    ...mapGetters(["getToken", "getUser"]),
  },
  data() {
    return {
      ref: false,
      alert: null,
      email: "",
      password: "",
    }
  },
  mounted() {
    // Verifica se o parâmetro 'ref' está presente na query string
    if (this.$route.query.ref) {
      const refValue = this.$route.query.ref;
      this.ref = refValue;
      console.log("ref: ", this.ref)
    } else {
      console.log("ref: ", this.ref)
    }
  },
  methods: {
  async handleSubmit() {
    try {
      const email = this.email;
      const password = this.password;

      const response = await loginService(email, password);

      // Se a resposta for bem-sucedida e tiver um token
      if (response.status === 200 && response.data.token) {

        console.log("loginService response: ", response)

        // Redirecione o usuário para a rota raiz ("/")
        this.$router.push('/home');
      } else {
        // Trate outros cenários conforme necessário
        console.log("loginService response: ", response)
      }
    } catch (error) {
      this.alert = "Falha de login. Tente novamente."
      console.error(error)
    }
  }
}
};
</script>

<style>
.deposit-alert h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: red;
}
.deposit-alert p {
  color: #fff;
}

.deposit-alert p strong {
  color: #84ff00;
}

.btn-deposit-alert {
  font-size: .8rem;
  font-weight: 600;
  color: white;
  background-color: red;
  border-radius: 0.4rem;
}
</style>

<template>
  <div
    class="login-wrapper d-flex align-items-center justify-content-center text-center"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10 col-lg-8">
          <img
            class="big-logo"
            src="../assets/img/white.png"
            alt="white logo"
          />
          <div class="register-form mt-5">
            <form action="recovery-password" method="POST">
              <div class="form-group text-start mb-4">
                <span>E-mail</span>
                <label for="email"><i class="fa-solid fa-envelope"></i></label>
                <input
                  class="form-control"
                  name="email"
                  type="text"
                  placeholder="Digite seu email de cadastro"
                />
                <input
                  class="form-control"
                  name="token"
                  type="text"
                  value="29923"
                  hidden=""
                />
              </div>
              <button class="btn btn-warning btn-lg w-100" type="submit">
                Recuperar senha
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordRecoveryView",
};
</script>

<style></style>

<template>
  <div
    class="offcanvas offcanvas-start suha-offcanvas-wrap"
    tabindex="-1"
    id="suhaOffcanvas"
    aria-labelledby="suhaOffcanvasLabel"
    aria-modal="true"
    role="dialog"
  >
    <button
      class="btn-close btn-close-white"
      type="button"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
    <div class="offcanvas-body">
      <div class="sidenav-profile">
        <div class="user-profile">
          <img src="../assets/img/white-no-text.png" alt="" />
        </div>
        <div class="user-info">
          <div class="live-chat-intro">
            <h5 class="user-name mb-1 text-white">{{ doisNomes.toUpperCase() }}</h5>
            <div class="status online">Online</div>
          </div>
        </div>
      </div>
      <ul class="sidenav-nav ps-0">
        <li>
          <router-link to="/home"><i class="fa-solid fa-cubes"></i> Hackers</router-link>
        </li>
        <li>
          <router-link to="/perfil"><i class="fa-solid fa-user"></i>Minha Conta</router-link>
        </li>
        <li>
          <router-link to="/aulas"><i class="fa-solid fa-graduation-cap"></i>Aulas</router-link>
        </li>
        <li>
          <router-link to="sair"><i class="fa-solid fa-sign-out"></i>Sair</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getToken", "getUser"]),
    doisNomes() {
      const doisNomes = this.getUser.name.split(" ");
      if (doisNomes.length < 1) {
        return "Desconhecido (erro)";
      }
      if (doisNomes.length === 1) {
        return doisNomes[0];
      }
      if (doisNomes.length > 1) {
        return doisNomes[0] + " " + doisNomes[1];
      }
      return "Desconhecido (erro)";
    },
  },
};
</script>

<style></style>

<template>
  <div
    class="login-wrapper d-flex align-items-center justify-content-center text-center"
  >
    <div class="background-shape"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10 col-lg-8">
          <img
            class="big-logo"
            src="../assets/img/white.png"
            alt="white logo"
          />
          <div class="register-form mt-5">
            <form>
              <!-- ALERTS -->
              <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="alert.show">
                <i class="fa-solid fa-triangle-exclamation"></i> Atenção
                <ul>
                  <li v-for="(msg, idx) in alert.message" :key="idx">{{ msg }}</li>
                </ul>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="alertDimiss"></button>
              </div>
              <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="alertSuccess.show">
                <i class="fa-solid fa-square-check"></i> Sucesso
                <ul>
                  <li>Cadastro realizado com sucesso. Acesse a página de login ou <router-link to="/login">clique aqui</router-link>.</li>
                </ul>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="alertDimissSuccess"></button>
              </div>
              <!-- FIM ALERTS -->
              <div class="form-group text-start mb-4">
                <span>CPF</span>
                <label for="cpf"><i class="fa-solid fa-id-card"></i></label>
                <input
                  class="form-control"
                  name="cpf"
                  type="text"
                  placeholder="CPF"
                  v-mask="'###.###.###-##'"
                  v-model="cpf"
                  @blur="handleSearch"
                />
              </div>
              <div class="form-group text-start mb-4" v-if="fullName">
                <span>Nome</span>
                <label for="nome"><i class="fa-solid fa-id-card"></i></label>
                <input
                  class="form-control"
                  name="nome"
                  type="text"
                  :value="fullName"
                  disabled
                />
              </div>
              <div class="form-group text-start mb-4">
                <span>Usuário</span>
                <label for="username"><i class="fa-solid fa-at"></i></label>
                <input
                  class="form-control"
                  name="usuario"
                  type="text"
                  placeholder="Digite um nome de usuário"
                  v-model="payload.username"
                />
              </div>
              <div class="form-group text-start mb-4">
                <span>E-mail</span>
                <label for="email"><i class="fa-solid fa-envelope"></i></label>
                <input
                  class="form-control"
                  name="email"
                  type="email"
                  placeholder="Digite seu email"
                  v-model="payload.email"
                />
              </div>
              <div class="form-group text-start mb-4">
                <span>Telefone</span>
                <label for="email"><i class="fa-solid fa-phone"></i></label>
                <input
                  class="form-control"
                  name="telefone"
                  type="text"
                  placeholder="Digite seu telefone"
                  v-mask="'(##) # ####-####'"
                  v-model="payload.phone"
                />
              </div>
              <div class="form-group text-start mb-4">
                <span>Senha</span>
                <label for="password"><i class="fa-solid fa-key"></i></label>
                <input
                  class="input-psswd form-control"
                  name="senha"
                  id="registerPassword"
                  type="password"
                  placeholder="Digite sua senha"
                  v-model="payload.password"
                />
              </div>
              <input name="cadastro" type="text" value="22/06/2023" hidden="" />
              <input name="status" type="text" value="Ativo" hidden="" />
              <input name="token" type="text" value="21089" hidden="" />
              <button class="btn btn-warning btn-lg w-100" type="button" @click="handleSubmit">
                Cadastre-se
              </button>
            </form>
          </div>
          <!-- Login Meta-->
          <div class="login-meta-data">
            <p class="mt-3 mb-0">
              Já possui cadastro?<router-link class="mx-1" to="/login">Entre</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchCpf, registerService } from '@/services/UserService.js';

export default {
  name: "CadastroView",
  data() {
    return {
      alert: {
        show: false,
        message: [],
      },
      alertSuccess: {
        show: false,
      },
      ref: "",
      cpf: "",
      username: "",
      fullName: null,
      payload: {
        username: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
      },
    }
  },
  created() {
    // Verifica se o parâmetro 'ref' está presente na query string
    if (this.$route.query.ref) {
      const refValue = this.$route.query.ref;
      this.ref = refValue;
      console.log("ref: ", this.ref)
    } else {
      console.log("ref: ", this.ref)
    }
  },
  mounted() {
    // this.handleSubmit()
  },
  methods: {
    async handleSearch() {
      await searchCpf(this.cpf)
        .then(res => {
          console.log("searchCpf response: ", res)
          if (res.status === 200 && res.data) {
            let nameArr = res.data.Result.NomePessoaFisica.split(" ")
            this.fullName = res.data.Result.NomePessoaFisica
            this.payload.name = nameArr[0]
            this.payload.last_name = nameArr[nameArr.length -1]
            this.payload.birthdate = res.data.Result.DataNascimento,
            this.payload.country = res.data.BasicData.BirthCountry === "BRASILEIRA" ? "br" : "",
            this.payload.document = res.data.Result.NumeroCpf,
            this.payload.ref = this.ref
          }
          console.log("payload: ", this.payload)
        })
        .catch(err => {
          console.error(err)
          this.alert.show = true
          this.alert.message = Object.values(err.response.data.errors).flatMap(errorArray => errorArray)
        })
    },

    async handleSubmit() {
      this.payload.password_confirmation = this.payload.password
      await registerService(this.payload)
        .then(res => {
          if (res.status === 200 && res.data) {
            this.alertSuccess.show = true
            setTimeout(() => {
              this.$router.push(`/login?ref=${this.ref}`)
            }, 5000)
          }
        })
        .catch(err => {
          console.error(err)
          this.alert.show = true
          this.alert.message = Object.values(err.response.data.errors).flatMap(errorArray => errorArray)
        })
    },

    alertDimiss() {
      this.alert.show = false
      this.alert.message = []
    },
    alertDimissSuccess() {
      this.alertSuccess.show = false
    }
  },
};
</script>

<style scoped>
ul li {
  text-align: initial;
  list-style: inherit;
  font-size: .9rem;
  font-weight: 700;
}
</style>

/* eslint-disable */
<template>
  <div class="page-content-wrapper py-3">
    <div class="container">
      <div class="row gy-3">
        <router-link to="/mines"></router-link>
        <div class="col-12">
          <router-link to="/mines"></router-link>
          <div
            class="single-vendor-wrap bg-img p-4 bg"
            style="
              background-image: url(https://bethacker.app/img/bg-img/mines.png);
            "
          >
            <router-link to="/mines">
              <h5 class="vendor-title text-white">Hacker Mines</h5>
              <div class="vendor-info">
                <p class="mb-1 text-white"><br /></p>
                <div class="ratings lh-1">
                  <i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i>
                </div>
              </div> </router-link
            ><router-link class="btn btn-warning btn-sm mt-3" to="/mines"
              >Hackear<i class="fa-solid fa-arrow-right-long ms-1"></i
            ></router-link>
          </div>
        </div>

        <router-link to="/roleta"></router-link>
        <div class="col-12">
          <router-link to="/roleta"></router-link>
          <div
            class="single-vendor-wrap bg-img p-4 bg"
            style="
              background-image: url(https://bethacker.app/img/bg-img/roleta.png);
            "
          >
            <router-link to="/roleta">
              <h5 class="vendor-title text-white">Hacker Roleta</h5>
              <div class="vendor-info">
                <p class="mb-1 text-white"><br /></p>
                <div class="ratings lh-1">
                  <i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i>
                </div>
              </div> </router-link
            ><router-link class="btn btn-warning btn-sm mt-3" to="/roleta"
              >Hackear<i class="fa-solid fa-arrow-right-long ms-1"></i
            ></router-link>
          </div>
        </div>

        <router-link to="/aviator"></router-link>
        <div class="col-12">
          <router-link to="/aviator"> </router-link>
          <div
            class="single-vendor-wrap bg-img p-4 bg-"
            style="
              background-image: url(https://bethacker.app/img/bg-img/aviator.png);
            "
          >
            <router-link to="/aviator">
              <h5 class="vendor-title text-white">Hacker Aviator</h5>
              <div class="vendor-info">
                <p class="mb-1 text-white"><br /></p>
                <div class="ratings lh-1">
                  <i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i>
                </div>
              </div> </router-link
            ><router-link class="btn btn-warning btn-sm mt-3" to="/aviator"
              >Hackear<i class="fa-solid fa-arrow-right-long ms-1"></i
            ></router-link>
          </div>
        </div>

        <!-- <router-link to="/footballx"><router-link>
        <div class="col-12">
          <router-link to="/footballx"> <router-link>
          <div
            class="single-vendor-wrap bg-img p-4 bg"
            style="
              background-image: url(https://bethacker.app/img/bg-img/footballx.png);
            "
          >
            <router-link to="/footballx">
              <h5 class="vendor-title text-white">Hacker FootballX</h5>
              <div class="vendor-info">
                <p class="mb-1 text-white"><br /></p>
                <div class="ratings lh-1">
                  <i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i>
                </div>
              </div> <router-link
            ><router-link class="btn btn-warning btn-sm mt-3" to="/footballx"
              >Hackear<i class="fa-solid fa-arrow-right-long ms-1"></i
            ><router-link>
          </div>
        </div> -->

        <router-link to="/bacbo"></router-link>
        <div class="col-12">
          <router-link to="/bacbo"> </router-link>
          <div
            class="single-vendor-wrap bg-img p-4 bg"
            style="
              background-image: url(https://ciaa.io/images/banner_tiger.png);
            "
          >
            <router-link to="/bacbo">
              <h5 class="vendor-title text-white">Hacker Tigger</h5>
              <div class="vendor-info">
                <p class="mb-1 text-white"><br /></p>
                <div class="ratings lh-1">
                  <i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i>
                </div>
              </div> </router-link
            ><router-link class="btn btn-warning btn-sm mt-3" to="/bacbo"
              >Hackear<i class="fa-solid fa-arrow-right-long ms-1"></i
            ></router-link>
          </div>
        </div>

        <router-link to="/luva"></router-link>
        <div class="col-12">
          <router-link to="/luva"> </router-link>
          <div
            class="single-vendor-wrap bg-img p-4 bg"
            style="
              background-image: url(https://ciaa.io/images/banner_rabbit.png);
            "
          >
            <router-link to="/luva">
              <h5 class="vendor-title text-white">Hacker Rabbit</h5>
              <div class="vendor-info">
                <p class="mb-1 text-white"><br /></p>
                <div class="ratings lh-1">
                  <i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i
                  ><i class="fa-solid fa-star"></i>
                </div>
              </div> </router-link
            ><router-link class="btn btn-warning btn-sm mt-3" to="/luva"
              >Hackear<i class="fa-solid fa-arrow-right-long ms-1"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>

/* eslint-disable */
<template>
  <div class="page-content-wrapper">
    <div class="container">
      <!-- Profile Wrapper-->
      <div class="profile-wrapper-area py-3">
        <!-- User Information-->
        <div class="card user-info-card">
          <div class="card-body p-4 d-flex align-items-center">
            <div class="user-profile me-3">
              <img src="img/perfil.png" alt="" />
            </div>
            <div class="user-info">
              <p class="mb-0 text-white">@Neto</p>
              <h5 class="mb-0 text-white">Thayllan magalhães</h5>
            </div>
          </div>
        </div>
        <!-- User Meta Data-->
        <div class="card user-data-card">
          <div class="card-body bg-dark">
            <form
              action="resources/atualizar-senha?SAD897ASD-1J8972Q8789QW-nbhjkas78687asasd-7b88991273519374234=291653j162s6173t152"
              method="POST"
            >
              <input type="text" name="id" value="72" hidden="" />
              <div class="mb-3">
                <div class="title mb-2 text-white">
                  <i class="fa-solid fa-key"></i><span>Senha atual</span>
                </div>
                <input class="form-control bg-dark" type="password" />
              </div>
              <div class="mb-3">
                <div class="title mb-2 text-white">
                  <i class="fa-solid fa-key"></i><span>Nova Senha</span>
                </div>
                <input
                  class="input-psswd form-control bg-dark"
                  name="senha"
                  id="registerPassword"
                  type="password"
                />
              </div>
              <div class="mb-3">
                <div class="title mb-2 text-white">
                  <i class="fa-solid fa-key"></i><span>Confirmar Senha</span>
                </div>
                <input class="form-control bg-dark" type="password" />
                <div
                  class="password-meter-wrapper progress"
                  style="display: none"
                >
                  <div
                    id="password-progress"
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="1"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="width: 1%"
                  ></div>
                </div>
                <div
                  id="password-score"
                  class="password-score"
                  style="display: none"
                ></div>
                <div
                  id="password-recommendation"
                  class="password-recommendation"
                ></div>
                <input type="hidden" id="password-strength-score" value="0" />
              </div>
              <button class="btn btn-success w-100" type="submit">
                Atualizar Senha
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.user-info-card {
  background: linear-gradient(126deg,#172d00 0%,#62be00 100%);
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Aulas from "../views/Aulas.vue";
import Perfil from "../views/Perfil.vue";
import PasswordChange from "../views/PasswordChange.vue";
import Login from "../views/Login.vue";
import PasswordRecovery from "../views/PasswordRecovery.vue";
import Cadastro from "../views/Cadastro.vue";
import Mines from "../views/games/Mines.vue";
import Roleta from "../views/games/Roleta.vue";
import Aviator from "../views/games/Aviator.vue";
import Footballx from "../views/games/Footballx.vue";
import Bacbo from "../views/games/Bacbo.vue";
import Luva from "../views/games/Luva.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login", // Redirecionar a rota raiz para /login
  },
  {
    path: "/home",
    component: Home,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
  {
    path: "/aulas",
    component: Aulas,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
  {
    path: "/perfil",
    component: Perfil,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
  {
    path: "/alterarSenha",
    component: PasswordChange,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/recuperarSenha",
    component: PasswordRecovery,
  },
  // {
  //   path: "/cadastrar",
  //   component: Cadastro,
  // },
  {
    path: "/mines",
    component: Mines,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
  {
    path: "/roleta",
    component: Roleta,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
  {
    path: "/aviator",
    component: Aviator,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
  {
    path: "/footballx",
    component: Footballx,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
  {
    path: "/bacbo",
    component: Bacbo,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
  {
    path: "/luva",
    component: Luva,
    meta: { requiresAuth: true }, // Rota protegida que requer autenticação
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // Verificar se a rota requer autenticação
  if (to.meta.requiresAuth) {
    // Verificar se o usuário está autenticado
    if (store.getters.getToken) {
      // O usuário está autenticado, permitir o acesso à rota
      next();
    } else {
      // O usuário não está autenticado, redirecionar para a página de login
      next("/login");
    }
  } else {
    // A rota não requer autenticação, permitir o acesso
    next();
  }
});

export default router;


import store from '@/store';
import axios from 'axios';

export const loginService = async (email, password) => {
  try {
    const response = await axios.post('https://central.jrsports.bet/api/auth/signin', {
      username: email,
      password: password,
      cookie: "",
    });

    if (response.status === 200 && response.data) {
      const token = `Bearer ${response.data.token}`;
      store.commit('setToken', token);

      try {
        const res = await axios.post(
          `https://central.jrsports.bet/api/clientes/getCliente/${response.data.user.id}`,
          {},
          {
            headers: {
              Authorization: token
            }
          }
        );

        if (res.status === 200 && res.data) {
          const user = res.data.results;
          store.commit('setUser', user);
        }
      } catch (error) {
        throw error;
      }
    }

    return response;
  } catch (error) {
    throw error;
  }
};

export const searchCpf = async (cpf, email, phone, username, password, ref) => {
  try {
    const response = await axios.post('https://backoffice.nordestepix.bet/api/search', {
      cpf: cpf
    });

    if (response.status === 200 && response.data) {
      return response
    }
    return response
  } catch (error) {
    throw error;
  }
};

export const registerService = async (body) => {
  // const body = {
  //   name: nameArr[0],
  //   last_name: nameArr[nameArr.length -1],
  //   username: username,
  //   email: email,
  //   birthdate: response.data.Result.DataNascimento,
  //   country: response.data.BasicData.BirthCountry === "BRASILEIRA" ? "br" : "",
  //   document: response.data.Result.NumeroCpf,
  //   password: password,
  //   password_confirmation: password,
  //   phone: phone,
  //   ref: ref
  // }
  try {
    const response = await axios.post('https://backoffice.nordestepix.bet/api/register', body)

    if (response.status === 200 && response.data) {
      return response
    }
    return response
  } catch (error) {
    throw error;
  }
}


export default { loginService, searchCpf, registerService };

/* eslint-disable */
<template>
  <div class="page-content-wrapper">
    <div class="container">
      <!-- Profile Wrapper-->
      <div class="profile-wrapper-area py-3">
        <!-- User Information-->
        <div class="card user-info-card">
          <div class="card-body p-4 d-flex align-items-center">
            <div class="user-profile me-3">
              <img src="../assets/img/color-no-text.png" alt="" />
            </div>
            <div class="user-info">
              <p class="mb-0 text-white">{{ getUser.login }}</p>
              <h5 class="mb-0 text-white">{{ doisNomes.toUpperCase() }}</h5>
            </div>
          </div>
        </div>
        <!-- User Meta Data-->
        <div class="card user-data-card">
          <div class="card-body bg-dark">
            <div
              class="single-profile-data d-flex align-items-center justify-content-between"
            >
              <div class="title d-flex align-items-center text-white">
                <i class="fa-solid fa-at"></i><span>Usuário</span>
              </div>
              <div class="data-content">{{ getUser.login }}</div>
            </div>
            <div
              class="single-profile-data d-flex align-items-center justify-content-between"
            >
              <div class="title d-flex align-items-center text-white">
                <i class="fa-solid fa-user"></i><span>Nome e Sobrenome</span>
              </div>
              <div class="data-content">{{ doisNomes.toUpperCase() }}</div>
            </div>
            <div
              class="single-profile-data d-flex align-items-center justify-content-between"
            >
              <div class="title d-flex align-items-center text-white">
                <i class="fa-solid fa-phone"></i><span>Telefone</span>
              </div>
              <div class="data-content">{{ getUser.telefone }}</div>
            </div>
            <div
              class="single-profile-data d-flex align-items-center justify-content-between"
            >
              <div class="title d-flex align-items-center text-white">
                <i class="fa-solid fa-envelope"></i><span>E-mail</span>
              </div>
              <div class="data-content">{{ getUser.email }}</div>
            </div>
            <!-- Edit Profile-->
            <div class="edit-profile-btn mt-3">
              <router-link class="btn btn-secondary w-100" to="/alterarSenha"
                ><i class="fa-solid fa-lock me-2"></i>Alterar Senha</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getToken", "getUser"]),
    doisNomes() {
      const doisNomes = `${this.getUser.nome} ${this.getUser.sobrenome}`;
      return doisNomes;
    },
  },
};
</script>

<style scoped>
.user-info-card {
  background: linear-gradient(126deg,#172d00 0%,#62be00 100%);
}
</style>

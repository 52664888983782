<template>
  <div class="header-area" id="headerArea">
    <div
      class="container h-100 d-flex align-items-center justify-content-between rtl-flex-d-row-r"
    >
      <div class="back-button me-2">
        <router-link to="/home" v-show="voltar"
          ><i class="fa-solid fa-arrow-left-long text-white"></i
        ></router-link>
      </div>
      <div class="page-heading">
        <h6 class="mb-0 text-white">{{ pageTitle }}</h6>
      </div>
      <div
        class="suha-navbar-toggler ms-2"
        data-bs-toggle="offcanvas"
        data-bs-target="#suhaOffcanvas"
        href="suhaOffcanvas"
        aria-controls="suhaOffcanvas"
      >
        <div class=""><span></span><span></span><span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    voltar: Boolean,
    pageTitle: String,
  },
};
</script>

<style></style>
